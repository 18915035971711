<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="mx-auto text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(mx-auto text-inst46 text-center leading-inst font-600)">
      {{ $t('partner.second.t1') }}
    </div>
    <div class="hidden mx-auto mt-10px text-inst17 text-center leading-inst md:(text-inst17) lg:(mx-auto mt-10px text-inst22 text-center leading-inst)">
      {{ $t('partner.second.t2') }}
    </div>
    <div class="mt-30px flex flex-col lg:(mt-40px flex flex-col)">
      <div class="flex justify-center lg:(flex justify-center)">
        <div class="w-150px h-220px mr-10px lg:(w-240px h-360px mr-20px)">
          <img src="@/assets/images/inst-app/1.png" alt="" class="w-full h-full">
        </div>
        <div class="w-150px h-220px ml-10px lg:(w-240px h-360px ml-20px)">
          <img src="@/assets/images/inst-app/2.png" alt="" class="w-full h-full">
        </div>
      </div>
      <div class="mt-35px flex flex-col items-center lg:(mt-40px flex flex-row justify-between items-start)">
        <div
          v-for="(item, index) in list"
          :key="item.title"
          class="flex flex-col items-center px-22px md:(px-0) lg:(px-0 max-w-330px flex flex-col items-center)"
          :class="[index === list.length -1 ? '' : 'mb-33px lg:(mb-0)']"
        >
          <div>
            <inst-icon icon-class="done" style="width:28px;height:28px;" />
          </div>
          <div class="mt-18px text-inst20 text-inst000 text-center leading-inst font-600 md:(w-370px text-inst17) lg:(w-330px mt-12px text-inst20 text-inst000 text-center leading-inst font-600)">{{ $t(item.title1) }}</div>
          <div class="mt-5px text-inst16 text-inst888 text-center leading-inst font-400 md:(mt-5px w-370px text-inst15) lg:(w-330px mt-10px text-inst17 text-inst888 text-center leading-inst font-400)">{{ $t(item.title2) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { title1: 'partner.second.list.t1', title2: 'partner.second.list.t2' },
        { title1: 'partner.second.list.t3', title2: 'partner.second.list.t4' },
        { title1: 'partner.second.list.t5', title2: 'partner.second.list.t6' }
      ]
    }
  }
}
</script>

<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="mx-auto text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(mx-auto text-inst46 text-center leading-inst font-600)">
      {{ $t('partner.fouth.t1') }}
    </div>
    <div class="hidden mx-auto mt-10px text-inst14 text-center leading-inst md:(text-inst17) lg:(mx-auto mt-10px text-inst22 text-center leading-inst)">
      {{ $t('partner.fouth.t2') }}
    </div>
    <!-- lg content -->
    <div class="hidden lg:(block mt-40px text-center)">
      <!-- top -->
      <div class="lg:(flex justify-between)">
        <div v-for="item in topList" :key="item.title1" class="lg:(max-w-330px flex flex-col items-center text-center)">
          <div>
            <inst-icon :icon-class="item.icon" style="width:70px;height:70px;" />
          </div>
          <div class="lg:(mt-12px text-inst20 text-inst111 leading-inst font-600)">{{ $t(item.title1) }}</div>
          <div class="lg:(mt-5px text-inst17 text-inst888 leading-inst font-400)">{{ $t(item.title2) }}</div>
        </div>
      </div>
      <!-- bottom -->
      <div class="lg:(mt-50px flex justify-between)">
        <div v-for="item in bottomList" :key="item.title1" class="lg:(max-w-330px flex flex-col items-center text-center)">
          <div>
            <inst-icon :icon-class="item.icon" style="width:70px;height:70px;" />
          </div>
          <div class="lg:(mt-12px text-inst20 text-inst111 leading-inst font-600)">{{ $t(item.title1) }}</div>
          <div class="lg:(mt-5px text-inst17 text-inst888 leading-inst font-400)">{{ $t(item.title2) }}</div>
        </div>
      </div>
    </div>
    <!-- sm content -->
    <div class="block mt-35px text-center lg:(hidden)">
      <div class="flex flex-col">
        <div
          v-for="(item, index) in allList"
          :key="item.title1"
          class="flex flex-col items-center px-22px"
          :class="index === allList.length - 1 ? '' : 'mb-35px lg:(mb-0)'"
        >
          <div>
            <inst-icon :icon-class="item.icon" style="width:70px;height:70px;" />
          </div>
          <div class="mt-22px text-inst20 text-inst111 leading-inst font-600 md:(w-370px text-inst17)">{{ $t(item.title1) }}</div>
          <div class="max-w-291px mt-5px text-inst16 text-center text-inst888 leading-inst font-400 md:(mt-5px max-w-370px text-inst15)">{{ $t(item.title2) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      topList: [
        { icon: 'service_team', title1: 'partner.fouth.list.t1', title2: 'partner.fouth.list.t2' },
        { icon: 'service_skills', title1: 'partner.fouth.list.t3', title2: 'partner.fouth.list.t4' },
        { icon: 'service_24h', title1: 'partner.fouth.list.t5', title2: 'partner.fouth.list.t6' }
      ],
      bottomList: [
        { icon: 'service_social', title1: 'partner.fouth.list.t7', title2: 'partner.fouth.list.t8' },
        { icon: 'service_operation', title1: 'partner.fouth.list.t9', title2: 'partner.fouth.list.t10' },
        { icon: 'service_risk', title1: 'partner.fouth.list.t11', title2: 'partner.fouth.list.t12' }
      ],
      allList: []
    }
  },
  created () {
    this.allList = [...this.topList, ...this.bottomList]
  }
}
</script>

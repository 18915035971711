<template>
  <div class="bussiness">
    <inst-swiper />
    <inst-app />
    <inst-admin />
    <inst-service />
    <inst-introduce />
    <inst-come />
  </div>
</template>

<script>
import InstSwiper from './components/inst-swiper.vue'
import InstApp from './components/inst-app.vue'
import InstAdmin from './components/inst-admin.vue'
import InstService from './components/inst-service.vue'
import InstIntroduce from './components/inst-introduce.vue'
import InstCome from './components/inst-come.vue'

export default {
  components: {
    'inst-swiper': InstSwiper,
    'inst-app': InstApp,
    'inst-admin': InstAdmin,
    'inst-service': InstService,
    'inst-introduce': InstIntroduce,
    'inst-come': InstCome
  }
}
</script>

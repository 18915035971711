<template>
  <section class="mt-30px mx-auto lg:(mt-60px mx-auto)">
    <div
      class="px-inst22 mx-auto text-inst28 text-center leading-inst font-600 md:(px-0 w-680px mx-auto text-inst36) lg:(px-0 w-1060px mx-auto text-inst56 text-center leading-inst font-600)"
    >
      {{ $t("partner.first.t1") }}
    </div>
    <div
      class="px-inst22 mx-auto mt-10px text-inst16 text-center leading-inst md:(px-0 w-680px mx-auto text-inst17) lg:(px-0 w-1060px mx-auto mt-10px text-inst32 text-center leading-inst)"
    >
      {{ $t("partner.first.t2") }}
    </div>
    <!-- lg swiper -->
    <div class="lg-content hidden lg:(block mt-40px h-596px)">
      <Swiper ref="lg-swiper" :options="lgSwiperOption" class="h-full">
        <SwiperSlide
          v-for="(item, index) in imgList"
          :key="index"
          class="flex justify-center h-full"
        >
          <img :src="item.img" alt="" class="h-full lg:(w-1060px)">
        </SwiperSlide>
        <div class="swiper-pagination" slot="pagination"></div>
      </Swiper>
    </div>
    <!-- sm swiper -->
    <div class="sm-content mt-30px block lg:(hidden)">
      <Swiper ref="sm-swiper" :options="smSwiperOptions" class="h-full">
        <SwiperSlide
          v-for="(item, index) in imgList"
          :key="index"
          class="flex justify-center h-full"
        >
          <img :src="item.img" alt="" class="h-full lg:(w-1060px)">
        </SwiperSlide>
        <div class="swiper-pagination" slot="pagination"></div>
      </Swiper>
    </div>
    <!-- lg title and desc -->
    <div class="hidden lg:(block)">
      <div class="w-full px-inst22 mt-20px mx-auto text-center text-inst20 text-inst111 leading-inst font-600 lg:(px-0 mt-20px w-1060px mx-auto text-center text-inst30 leading-inst font-600)">
        {{ $t(lg.topTitle) }}
      </div>
      <div class="w-full px-inst22 mt-10px mx-auto text-center text-inst14 leading-inst font-400 lg:(px-0 mt-10px w-1060px mx-auto text-center text-inst17 leading-inst font-400)">
        {{ $t(lg.bottomTitle) }}
      </div>
    </div>
    <!-- sm title and desc -->
    <div class="block lg:(hidden)">
      <div class="w-full px-inst22 mt-20px mx-auto text-center text-inst20 text-inst111 leading-inst font-600 md:(px-0 w-680px mx-auto) lg:(px-0 mt-20px w-1060px mx-auto text-center text-inst30 leading-inst font-600)">
        {{ $t(sm.topTitle) }}
      </div>
      <div class="w-full px-inst22 mt-10px mx-auto text-center text-inst14 leading-inst font-400 md:(px-0 w-680px mx-auto) lg:(px-0 mt-10px w-1060px mx-auto text-center text-inst17 leading-inst font-400)">
        {{ $t(sm.bottomTitle) }}
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    const _this = this
    return {
      imgList: [
        { img: require('@/assets/images/inst-swiper/2.jpg') },
        { img: require('@/assets/images/inst-swiper/3.jpg') },
        { img: require('@/assets/images/inst-swiper/1.jpg') }
      ],
      lg: {
        topTitle: '',
        bottomTitle: ''
      },
      sm: {
        topTitle: '',
        bottomTitle: ''
      },
      lgSwiperOption: {
        // 显示多少slides
        slidesPerView: 'auto',
        // 活动的图片 居中，默认在左边
        centeredSlides: true,
        // 图片间隔 单位px
        spaceBetween: 22,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true,
        loopedSlides: 5,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChange: function () {
            const currentIndex = this.realIndex
            if (currentIndex === 0) {
              _this.lg.topTitle = 'partner.first.list.t5'
              _this.lg.bottomTitle = 'partner.first.list.t6'
            }
            if (currentIndex === 1) {
              _this.lg.topTitle = 'partner.first.list.t3'
              _this.lg.bottomTitle = 'partner.first.list.t4'
            }
            if (currentIndex === 2) {
              _this.lg.topTitle = 'partner.first.list.t1'
              _this.lg.bottomTitle = 'partner.first.list.t2'
            }
          }
        }
      },
      smSwiperOptions: {
        // 活动的图片 居中，默认在左边
        centeredSlides: true,
        // 图片间隔 单位px
        spaceBetween: 0,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChange: function () {
            const currentIndex = this.realIndex
            if (currentIndex === 0) {
              _this.sm.topTitle = 'partner.first.list.t5'
              _this.sm.bottomTitle = 'partner.first.list.t6'
            }
            if (currentIndex === 1) {
              _this.sm.topTitle = 'partner.first.list.t3'
              _this.sm.bottomTitle = 'partner.first.list.t4'
            }
            if (currentIndex === 2) {
              _this.sm.topTitle = 'partner.first.list.t1'
              _this.sm.bottomTitle = 'partner.first.list.t2'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lg-content {
  .swiper-container {
    transform:translate3d(0,0,0);
    ::v-deep .swiper-pagination-bullet-active {
      background-color: #02D250;
    }
  }

  .swiper-slide {
    width: 1060px;
    transform:translate3d(0,0,0)
  }
}

.sm-content {
  .swiper-container {
    transform:translate3d(0,0,0);
    ::v-deep .swiper-pagination-bullet-active {
      background-color: #02D250;
    }
  }

  .swiper-slide {
    transform:translate3d(0,0,0);
  }
}

</style>
